import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"

//contacts
import Cart from "./cart&order/reducer"

//product
import Products from "./product/reducer"

//layout groups
import LayoutGroup from "./group&collections/reducer"

//banner
import Banner from "./banner/reducer"

//classification
import Classification from "./classifications/reducer"

//settings
import SiteSettings from "./settings/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Cart,
  Products,
  LayoutGroup,
  Banner,
  Classification,
  SiteSettings,
})

export default rootReducer
