import CategorySlider from "components/sliders/Category"
import React from "react"
import { useWindowSize } from "react-use"

function CategorySliderContainer() {
  const { width } = useWindowSize()
  return (
    <section className="popular-categories section-padding">
      <div className="container p-0 wow animate__fadeIn animate__animated">
        {width >= 768 && (
          <div className="section-title">
            <div className="title">
              <h3 className="mx-3">Categories</h3>
            </div>
          </div>
        )}
        <div className="carausel-10-columns-cover position-relative">
          <div
            className="carausel-10-columns new_cat_slider"
            id="carausel-10-columns "
          >
            <CategorySlider />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CategorySliderContainer
